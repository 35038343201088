<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="1500px">
      <v-card>
        <!-- Header -->
        <v-toolbar dark color="#179fdb" flat>
          <v-icon large color="#004e82">mdi-chat-plus</v-icon>
          <v-card-title class="text-h5">platform X - CoPilot</v-card-title>

          <v-row justify="end" style="margin-top: 0.4em">
            <span style="margin-right: 0.4em; margin-top: 0.05em; display: inline-block"
              :style="switchOn ? 'color:#e6e6e6;' : 'color:white;'">Schnell</span>
            <v-switch v-model="switchOn"></v-switch>
            <span style="margin-left: 0.2em; margin-top: 0.02em; display: inline-block"
              :style="switchOn ? 'font-weight: bold;' : 'color:#e6e6e6;'">Präzise</span>
          </v-row>

          <v-col cols="2" class="text-right" align-self="center">
            <v-btn icon dark @click.stop="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-toolbar>

        <v-divider></v-divider>

        <!-- Main Chat Area -->
        <v-card-text style="height: 800px">
          <div id="thisElement" style="scroll-margin-bottom: 100px">
            <v-container class="fill-height">
              <v-row class="fill-height pb-14" align="end">
                <v-col>
                  <div v-for="(item, index) in displayedMessages" :key="index" :class="[
                    'd-flex flex-row align-center my-2',
                    item.from === 'user' ? 'justify-end' : null
                  ]">
                    <!-- User Message -->
                    <div v-if="item.from === 'user'" class="blue--text mr-3" style="background-color:azure;">
                      {{ item.msg[0] }}
                    </div>

                    <!-- User Avatar -->
                    <v-avatar v-if="item.from === 'user'" color="#0a6aa8" size="36">
                      <span class="white--text">N</span>
                    </v-avatar>

                    <!-- Bot Avatar -->
                    <v-avatar v-if="item.from === 'sushant'" color="#004e82" size="36">
                      <span class="white--text">B</span>
                    </v-avatar>

                    <!-- Bot Message -->
                    <div v-if="item.from !== 'user' && !item.isLoading" class="black--text ml-3 message-container">
                      <div v-for="(entry, entryIndex) in item.msg" :key="entryIndex">
                        <div v-html="entry"></div>
                      </div>

                      <!-- Sources -->
                      <div v-if="item.sources && item.sources.length > 0">
                        Mögliche Quellen:
                        <span v-for="(entry, sourceIndex) in item.sources" :key="`source-${sourceIndex}`">
                          <a :href="entry" target="_blank">Quelle {{ sourceIndex + 1 }}</a>&nbsp;&nbsp;
                        </span>
                      </div>

                      <!-- Thread and Rating Buttons -->
                      <div class="d-flex align-center mt-2">
                        <!-- Thread Button -->
                        <v-btn small text color="grey" @click="startThread(item)" class="mr-2">
                          <v-icon small class="mr-1">mdi-chat-plus-outline</v-icon>
                          {{
                            item.threadCount
                              ? `${item.threadCount} Antworten`
                              : 'Folgefragen im neuen Fenster'
                          }}
                        </v-btn>
                      </div>

                      <!-- Like/Dislike -->
                      <div v-if="index !== 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="item.likeClicked" v-bind="attrs" v-on="on" tile icon
                              color="blue lighten-2" @click.stop="onLikeClicked(index, 1)">
                              <v-icon>mdi-thumb-up-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Klicke hier, um die Leistung unserer Suchergebnisse zu
                            verbessern.</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="item.likeClicked" v-bind="attrs" v-on="on" tile icon color="red lighten-2"
                              @click.stop="onLikeClicked(index, 0)">
                              <v-icon>mdi-thumb-down-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Klicke hier, um die Leistung unserer Suchergebnisse zu
                            verbessern.</span>
                        </v-tooltip>
                      </div>
                    </div>

                    <!-- Loading State -->
                    <div v-if="item.from !== 'user' && item.isLoading" class="black--text ml-5">
                      <v-row>
                        <v-progress-circular indeterminate color="#004e82"></v-progress-circular>
                      </v-row>
                      <v-row>
                        <div v-for="(entry, loadingIndex) in testContent" :key="`loading-${loadingIndex}`">
                          <!-- <pre style="white-space: pre-wrap;">{{ entry }}</pre> -->
                          <div v-html="entry"></div>
                        </div>
                        <!-- <div class="black--text ml-3">{{ testContent }}</div> -->
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>

        <!-- Thread Dialog -->
        <v-dialog v-model="showThreadDialog" max-width="700px">
          <v-card>
            <v-toolbar dark color="#179fdb" flat>
              <v-icon large color="#004e82">mdi-chat-processing</v-icon>
              <v-card-title class="text-h6">Folgefragen</v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="showThreadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text style="height: 400px" class="thread-messages">
              <!-- Parent Message -->
              <div v-if="activeThread && activeThread.parentMessage"
                class="parent-message pa-3 grey lighten-4 rounded mb-4">
                <!-- Add user message -->
                <div class="user-message mb-2 blue lighten-4 pa-2 rounded">
                  {{ activeThread.parentMessage.userMessage }}
                </div>
                <!-- Bot message -->
                <div v-html="activeThread.parentMessage.msg[0]"></div>
              </div>
              <!-- Thread Messages -->
              <div v-for="(message, threadIndex) in activeThread && activeThread.messages
                ? activeThread.messages
                : []" :key="`thread-${threadIndex}`"
                :class="['d-flex', 'my-2', message.from === 'user' ? 'justify-end' : '']">
                <div :class="[
                  'pa-2',
                  'rounded',
                  message.from === 'user' ? 'blue lighten-4' : 'grey lighten-3'
                ]">
                  <div v-if="Array.isArray(message.msg)">
                    <div v-for="(msgPart, partIndex) in message.msg" :key="`msg-part-${partIndex}`" v-html="msgPart">
                    </div>
                  </div>
                  <div v-else v-html="message.msg"></div>
                </div>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <!-- Thread Input -->
            <v-card-actions>
              <v-text-field v-model="threadMsg" placeholder="Folgefrage..." @keypress.enter="sendThreadMessage"
                full-width hide-details></v-text-field>
              <v-btn icon class="ml-2" @click="sendThreadMessage">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider></v-divider>

        <!-- Main Input -->
        <v-card-actions>
          <v-container class="ma-0 pa-0" fluid>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-text-field v-model="msg" placeholder="Stelle eine Frage"
                    @keypress.enter="onSendClick"></v-text-field>
                  <v-btn icon class="ml-4" @click="onSendClick">
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import axios from 'axios';
import { mapGetters } from 'vuex';
import { createChatBotQuestion, updateChatBotQuestion } from '@/graphql/mutations';
import { API } from 'aws-amplify';
import { marked } from 'marked';

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      jwt: '',
      switchOn: true,
      chat: [],
      msg: null,
      threadMsg: null,
      tokenBuffer: '',
      testContent: '',
      showThreadDialog: false,
      activeThread: null,
      threads: {},
      input: {
        // baseUrl: 'https://oagrtdcsnijsy77426vcpjmlqq0zsvef.lambda-url.eu-central-1.on.aws/', //prod new
        // baseUrl: 'https://7xq4lnug2frc3dw74pqju32gce0lfcxw.lambda-url.eu-central-1.on.aws/', //local
        // baseUrl: 'https://data-ai.api.dev.data.pl-x.cloud/',//prod
        baseUrl: 'https://7d34hzwwnw3nkxwpihnazfxiqy0iyuff.lambda-url.eu-central-1.on.aws/', //dev
        // baseUrl: 'http://localhost:8000/',
        // modelName: 'gpt-4o-2024-11-20',
        modelName: 'gpt-4o-mini',
        k: 12
      },
      data: {
        answer_length: 0,
        chat_model: '',
        helpful: 0,
        question: '',
        timestamp: 0,
        user: ''
      },
      likedButtonClicked: false,
      test: '123'
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    displayedMessages() {
      return this.chat.map(message => ({
        ...message,
        threadCount: this.getThreadCount(message)
      }));
    },
    ...mapGetters(['jwtToken', 'kurzel'])
  },
  created() {
    this.chat.push(
      this.createMessage('sushant', [
        'Hallo und herzlich willkommen! Kann ich Dir behilflich sein?'
      ])
    );
    this.loadThreadsFromStorage();
  },
  methods: {
    generateMessageId() {
      return `msg_${Date.now()}_${Math.random()
        .toString(36)
        .substr(2, 9)}`;
    },

    createMessage(from, msg, sources = [], isLoading = false, questionId = null) {
      return {
        from,
        msg: Array.isArray(msg) ? msg : [msg],
        sources: sources,
        isLoading,
        likeClicked: false,
        id: this.generateMessageId(),
        question_id: questionId
      };
    },

    async handleStreamingResponse(reader, messageIndex, isThread = false, questionId) {
      const decoder = new TextDecoder();
      let tokenBuffer = '';
      let sources = [];
      let messages = isThread ? this.activeThread.messages : this.chat;

      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            const finalResult = marked.parse(tokenBuffer);
            messages.splice(messageIndex, 1); // Remove loading message
            messages.push(this.createMessage('sushant', finalResult, sources, false, questionId));
            messages[messages.length - 1].sources = sources;

            if (isThread) {
              this.saveThreadsToStorage();
            }
            break;
          }

          const partialBuffer = decoder.decode(value, { stream: true });
          const lines = partialBuffer.split('\0');

          for (const line of lines) {
            if (line.startsWith('source:')) {
              sources.push(line.slice(7));
            } else if (line.startsWith('data:')) {
              const tokens = line.slice(5);
              tokenBuffer += tokens;

              const newMarkdown = tokenBuffer;
              if (newMarkdown.includes('\n')) {
                const lastIndex = this.findLastNewlineIndex(newMarkdown);
                const part1 = newMarkdown.substring(0, lastIndex);
                const part2 = newMarkdown.substring(lastIndex);
                messages[messageIndex].msg = [marked.parse(part1) + part2];
              } else {
                messages[messageIndex].msg = [tokenBuffer];
              }

              if (isThread) {
                this.saveThreadsToStorage();
              }
            }
          }
        }
      } catch (error) {
        console.error('Error in streaming response:', error);
        messages.splice(messageIndex, 1);
        messages.push(
          this.createMessage(
            'sushant',
            'Es tut mir leid, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.',
            [],
            false,
            questionId
          )
        );

        if (isThread) {
          this.saveThreadsToStorage();
        }
      }
    },

    findLastNewlineIndex(text) {
      let lastIndex = text.lastIndexOf('\n');
      while (lastIndex > 0 && text[lastIndex - 1] === '\n') {
        lastIndex--;
      }
      return lastIndex;
    },

    async makeApiCall(question, messageIndex, isThread = false, questionId) {
      const url = this.buildApiUrl();
      const messages = this.formatChatHistory(question, isThread ? this.activeThread : null);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: this.jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          messages: messages,
          k: this.input.k,
          model: this.input.modelName,
          stream: true
        })
      });

      await this.handleStreamingResponse(
        response.body.getReader(),
        messageIndex,
        isThread,
        questionId
      );
    },
    getThreadCount(message) {
      if (!message.id) return 0;
      const thread = this.threads[message.id];
      return thread ? thread.messages.length : 0;
    },
    async onLikeClicked(index, like) {
      this.data.timestamp = Math.floor(Date.now() / 1000);
      const lastAnswer = [...this.chat].pop();
      const lastQuestion = this.chat[this.chat.length - 2].msg;
      const input = {
        id: this.chat[index].question_id,
        answer_length: JSON.stringify(lastAnswer.msg).length,
        chat_model: this.input.modelName,
        helpful: like,
        feedback: 1,
        question: lastQuestion,
        timestamp: Math.floor(Date.now() / 1000),
        user: this.kurzel
      };
      await API.graphql({
        query: updateChatBotQuestion,
        variables: { input: input }
      });
      this.chat[index].likeClicked = true;
    },
    async saveQuestion(question) {
      const input = {
        answer_length: 0,
        chat_model: this.input.modelName,
        helpful: 0,
        feedback: 0,
        question: question,
        timestamp: Math.floor(Date.now() / 1000),
        user: this.kurzel
      };
      try {
        let myResp = await API.graphql({
          query: createChatBotQuestion,
          variables: { input: input }
        });
        return myResp.data.createChatBotQuestion.id;
      } catch (error) {
        return 0;
      }
    },
    loadThreadsFromStorage() {
      const savedThreads = localStorage.getItem('chatThreads');
      if (savedThreads) {
        this.threads = JSON.parse(savedThreads);
      }
    },
    saveThreadsToStorage() {
      localStorage.setItem('chatThreads', JSON.stringify(this.threads));
    },
    startThread(message) {
      if (!message.id) {
        message.id = this.generateMessageId();
      }

      if (!this.threads[message.id]) {
        // Find the user message that came before this bot message
        const messageIndex = this.chat.findIndex(m => m.id === message.id);
        const userMessage = messageIndex > 0 ? this.chat[messageIndex - 1].msg[0] : '';

        this.threads[message.id] = {
          parentMessage: {
            ...message,
            userMessage: userMessage
          },
          messages: []
        };
        this.saveThreadsToStorage();
      }

      this.activeThread = this.threads[message.id];
      this.showThreadDialog = true;
    },
    async sendThreadMessage() {
      if (!this.threadMsg || !this.threadMsg.trim() || !this.activeThread) return;

      this.activeThread.messages.push(this.createMessage('user', this.threadMsg));
      this.activeThread.messages.push(this.createMessage('sushant', ['']));

      const messageIndex = this.activeThread.messages.length - 1;
      const question = this.threadMsg;
      this.threadMsg = null;

      this.saveThreadsToStorage();
      let questionId = await this.saveQuestion(this.msg);
      await this.makeApiCall(question, messageIndex, true, questionId);
    },
    buildApiUrl() {
      return this.input.baseUrl + 'chat';
    },
    formatChatHistory(newMessage, thread = null) {
      const messages = [];

      if (thread) {
        // For threads, include parent message context and thread history
        if (thread.parentMessage) {
          // Add the original user question
          messages.push({
            role: 'user',
            content: thread.parentMessage.userMessage
          });

          // Add the original bot response
          messages.push({
            role: 'assistant',
            content: Array.isArray(thread.parentMessage.msg)
              ? thread.parentMessage.msg.join('\n')
              : thread.parentMessage.msg
          });
        }

        // Add thread message history
        for (const message of thread.messages) {
          if (message.from === 'user') {
            messages.push({
              role: 'user',
              content: Array.isArray(message.msg) ? message.msg[0] : message.msg
            });
          } else if (message.from === 'sushant' && !message.isLoading) {
            messages.push({
              role: 'assistant',
              content: Array.isArray(message.msg) ? message.msg.join('\n') : message.msg
            });
          }
        }
      }

      // Add new message
      if (newMessage) {
        messages.push({
          role: 'user',
          content: newMessage
        });
      }

      return messages;
    },
    async onSendClick() {
      if (!this.msg || !this.msg.trim()) return;

      this.likedButtonClicked = false;
      this.chat.push(this.createMessage('user', this.msg));
      this.chat.push(this.createMessage('sushant', [''], true));

      const messageIndex = this.chat.length - 1;
      const question = this.msg;
      this.msg = null;
      this.testContent = '';
      let questionId = await this.saveQuestion(question);
      await this.makeApiCall(question, messageIndex, false, questionId);
      this.moveScrollBar();
    },
    moveScrollBar() {
      let element = document.getElementById('thisElement');
      element.scrollIntoView(false, { behavior: 'smooth', block: 'end', inline: 'end' });
    }
  }
};
</script>

<style scoped>
.thread-messages {
  overflow-y: auto;
}

.parent-message {
  border-left: 3px solid #179fdb;
}

.message-container {
  max-width: 80%;
}

.user-message {
  display: inline-block;
}
</style>
